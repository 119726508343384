import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OhhProviderReview } from '../../../api/provider/models/ohh-provider-review.model';

@Component({
    selector: 'ohh-review-viewer',
    templateUrl: './review-viewer.component.html',
    styleUrl: './review-viewer.component.less'
})
export class ReviewViewerComponent {
    @Output() reviewEdited = new EventEmitter<OhhProviderReview>();

    @Input() heightInPx = 300;
    @Input() maxWidthInPx: number = null;
    @Input() reviews: OhhProviderReview[] = [];

    private _currentUserId: string;
    @Input() set currentUserId(value: string) {
        this._currentUserId = value;
    }

    get currentUserId(): string {
        return this._currentUserId;
    }

    protected showReviewDialog = false;
    protected reviewToEdit: OhhProviderReview;

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onReviewEdited(review: OhhProviderReview) {
        this.reviewToEdit = review;
        this.showReviewDialog = true;
    }

    onReviewSaved(review: OhhProviderReview) {
        this.reviewEdited.next(review);
        this.showReviewDialog = false;
    }
}
