<div id="ol-review-card-container">
    <div id="ol-provider-rating"  class="prevent-select" *ngIf="ratingIcons?.length > 0;">
        <fa-icon *ngFor="let x of ratingIcons; let i = index" [icon]="ratingIcons[i]"></fa-icon>
    </div>
    <div id="ol-rc-title" class="prevent-select" *ngIf="review?.reviewTitle" [innerHTML]="review?.reviewTitle"></div>
    <div id="ol-rc-review" class="prevent-select" [innerHTML]=reviewBody></div>
    <div id="ol-rc-client" class="prevent-select" *ngIf="review?.client" [innerHTML]=client></div>

	<div id="ol-review-edit-btn" class="ol-green-button" *ngIf="canEdit" (click)="onEditReview()"><span class="prevent-select">Edit Your Review<</span></div>
</div>
