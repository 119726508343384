<div id="ol-review-viewer-container" [ngStyle]="{'max-width': (maxWidthInPx != null ? maxWidthInPx + 'px' : 'unset'), 'height': heightInPx + 'px'}">
    <ohh-review-card
            *ngFor="let r of reviews"
            [review]="r"
			[canEdit]="r.customerId != null && r.customerId == currentUserId"
			(editReview)="onReviewEdited($event)"
    ></ohh-review-card>
</div>

<ohh-review-writer-dlg
		*ngIf="showReviewDialog"
		[providerId]="reviewToEdit.providerInfoId"
		[review] = reviewToEdit
		(reviewSaved)="onReviewSaved($event)"
		(cancel)="showReviewDialog = !showReviewDialog"
></ohh-review-writer-dlg>
