
export const environment = {
    name: 'local',
    production: false,
    appVersion: require('../../package.json').config.footerVersion,
    i18nCacheBuster: `-${require('../../package.json').config.i18nCacheBuster}`,
    suppressEmail: true,
    suppressRegistration: false,

    ohhClientUrl: 'https://ohhlook.thegsdgrp.com',
    ohhApiUrl: 'https://ohhlookapi.thegsdgrp.com',
    ohhIdentityUrl: 'https://ohhlookidentity.thegsdgrp.com',

    ohhBlobStorageAccount: 'ohhlookstoragedev',
    ohhBlobStorageContainer: 'ohhlook-assets',
    ohhBlobStorageUrl: 'https://ohhlookstoragedev.blob.core.windows.net/ohhlook-assets',

    ohhCdnUrl: 'https://ohhlook-cdn-f2fyfehebnggb5dt.z02.azurefd.net/ohhlook-assets',

    stripePublishKey: 'pk_live_51Kimi7FMXsw9jn8w3rr2Ue5pUQt8XqfSrjT7n6rfhjdWDJnhTKIOkWReD9AhMnOPQouozf4OUapRyGfYeB0OwYhv00QWQnQWwN',

    appInsightsInstKey: '_SetInAzure_'
}
