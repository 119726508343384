import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OhhProviderReview } from '../../../../api/provider/models/ohh-provider-review.model';

@Component({
    selector: 'ohh-review-card',
    templateUrl: './review-card.component.html',
    styleUrl: './review-card.component.less'
})
export class ReviewCardComponent {
    @Output() editReview = new EventEmitter<OhhProviderReview>();

    protected reviewBody: string;
    protected ratingIcons: any[];
    protected client: string;

    private _review: OhhProviderReview;
    @Input() set review(value: OhhProviderReview) {
        if (value == null) return;

        this._review = value;
        this.reviewBody = `"${this._review?.review}"`;
        this.client = this._review?.client ? `- ${this._review.client}` : undefined;
        this.setRatingIcons();
    }

    get review(): OhhProviderReview {
        return this._review;
    }

    @Input() canEdit: boolean = false;

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onEditReview() {
        this.editReview.next(this.review);
    }


    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setRatingIcons() {
        this.ratingIcons = [];
        const iconBase: any[] = ['far', 'star'];

        for (let i = 0; i < 5; i++) {
            let icon: any;

            if (i < this.review?.rating) {
                icon = (this.review?.rating - i >= 1 || this.review?.rating == 0) ? 'star' : 'star-half-stroke';
            } else {
                icon = iconBase;
            }

            this.ratingIcons.push(icon);
        }
    }
}
