import { ApiService, HttpMethod } from '../_common/api.service';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OhhProviderReview } from '../provider/models/ohh-provider-review.model';

@Injectable({
    providedIn: 'root'
})
export class ReviewApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // Ctor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    createReview(review: OhhProviderReview): Observable<OhhProviderReview> {
        return this.apiService.apiRequest<any>(ReviewApiService.createReviewUrl, HttpMethod.Post, review)
            .pipe(map((response: OhhProviderReview) => new OhhProviderReview(response)));
    }

    updateReview(review: OhhProviderReview): Observable<OhhProviderReview> {
        return this.apiService.apiRequest<any>(ReviewApiService.updateReviewUrl, HttpMethod.Put, review)
            .pipe(map((response: OhhProviderReview) => new OhhProviderReview(response)));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}reviews`; }

    private static get createReviewUrl(): string { return `${ReviewApiService.baseUrl}`; }

    private static get updateReviewUrl(): string { return `${ReviewApiService.baseUrl}`; }

}
